import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App'
import Home from './components/Home'
import LangHome from './components/LangHome'
import NotFound from './components/NotFound'
import LangPractice from './components/LangPractice'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Home },
    { path: '/:lang(js|python|html|java)', component: LangHome, props: true },
    { path: '/:lang(js|python|html|java)/:topic', component: LangPractice, props: true },
    { path: '/:path(.*)', component: NotFound, props: true }
  ]
})

const app = createApp(App)
app.use(router)
app.mount('#app')
