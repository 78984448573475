export const topicsList = [
  {
    code: 'prepare',
    topic: 'Подготовка',
    description: 'Настраиваем рабочее окружение'
  },
  {
    code: 'arithm',
    topic: 'Простая арифметика',
    description: 'В этой теме мы научимся делать простые арифметические вычисления с помощью языка программирования. Также важно будет получить навыки правильного оформления кода и использования клавиатурных сокращений'
  },
  {
    code: 'int',
    topic: 'Целочисленная арифметика',
    description: 'Для решения множества вычислительных задач очень часто применяется целочисленная арифметика. Основа целочисленной арифметики - это операция взятия остатка от деления и деление нацело'
  },
  {
    code: 'var',
    topic: 'Переменные, оператор присваивания',
    description: 'Ни одна программа не обходится без переменных. Очень важно уметь их применять. В этой теме потренируемся создавать и изменять переменные'
  },
  {
    code: 'boolean',
    topic: 'Истина и ложь',
    description: 'Одним из основных типов данных в JavaScript и в программировании в целом является тип boolean, который имеет всего два значения: true и false. В этой теме рассмотрим его, а также операторы, которые с ним связаны'
  },
  {
    code: 'condition',
    topic: 'Ветвления',
    description: 'Очень часто нужно выполнять разные наборы действий в зависимости от каких-то условий. Чтобы это было возможно, в языке появился соответствующий синтаксис, который мы и рассмотрим в этой теме'
  },
  {
    code: 'range',
    topic: 'Диапазоны чисел - range',
    description: 'В Python нет цикла со счётчиком, как в других языках. Вместо этого используется цикл, перебирающий все элементы последовательности. Одним из типов данных, представляющих последовательности, и самым употребимым из них, является range. В этой теме посмотрим, что с ним можно делать'
  },
  {
    code: 'loop',
    topic: 'Циклы',
    description: 'Что отличает компьютер от калькулятора? Калькулятор не поможет обработать много однотипных данных. С помощью циклов мы можем заставить компьютер делать однотиные действия многократно. Как раз этим и займёмся в этой теме'
  },
  {
    code: 'list',
    topic: 'Списки',
    description: 'Многократно повторяемые однотипные действия - это, конечно, хорошо. Но что толку, если мы не умеем запоминать большое количество данных? Списки - контейнеры для однотипных данных. С помощью них мы сможем ещё сильнее расширить возможности по использованию языка!'
  },
  {
    code: 'func',
    topic: 'Функции',
    description: 'До этого мы использовали функции, которые не возвращают никаких значений. Эти функции выполняли какие-то вычисления и выводили результат на консоль. При этом нельзя было использовать результат вычислений за пределами функции. Теперь рассмотрим другие возможности функций'
  }
]
topicsList.forEach((topic, idx) => {
  topic.getTasks = () => {
    const folder = `${String(idx + 1).padStart(2, '0')}-${topic.code}`
    return import('@/data/python/' + folder + '/tasks.js')
  }
})
export const topicsMap = new Map(topicsList.map(item => [item.code, item]))
