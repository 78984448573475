<template>
  <h2 class="text-center">{{ topicName }}</h2>
  <div class="text-center">Код: {{ topic }}</div>
  <div class="mt">
    <div
      v-for="(label, i) in labels"
      :key="i"
      class="pagination-item"
      @click="activeTask = i"
      :class="{ active: activeTask === i }"
    >
      {{ label }}
    </div>
    <router-link :to="`/${lang}`" class="pagination-item"
      >Вернуться к темам</router-link
    >
  </div>
  <div class="mt" v-if="tasks.length">
    <component
      :is="tasks[activeTask].component"
      :task="tasks[activeTask]"
    />
  </div>
</template>

<script>
import { topics } from '../data/langTopics'
import { shallowRef, ref, onMounted } from 'vue'

export default {
  name: 'LangPractice',
  props: ['lang', 'topic', 'num'],
  setup(props) {
    const { topic: topicName, getTasks } = topics[props.lang].map.get(props.topic)
    const tasks = shallowRef([])
    const labels = shallowRef([])
    onMounted(async () => {
      tasks.value = (await getTasks()).default
      let num = 0
      labels.value = tasks.value.map((t) => t.label || ++num)
    })
    const activeTask = ref(props.num || 0)
    return {
      topicName,
      tasks,
      labels,
      activeTask
    }
  }
}
</script>
