export const topicsList = [
  {
    code: 'prepare',
    topic: 'Ликбез по командной строке',
    description: 'Любому программисту приходится сталкиваться с командной строкой. Фронтенд-разработчик не исключение. Кроме того, на начальном этапе все решения мы будем проверять с помощью командной строки. Поэтому понимать основы работы с ней необходимо'
  },
  {
    code: 'arithm',
    topic: 'Простая арифметика',
    description: 'В этой теме мы научимся делать простые арифметические вычисления с помощью языка программирования. Также важно будет получить навыки правильного оформления кода и использования клавиатурных сокращений'
  },
  {
    code: 'int',
    topic: 'Целочисленная арифметика',
    description: 'Для решения множества вычислительных задач очень часто применяется целочисленная арифметика. Основа целочисленной арифметики - это операция взятия остатка от деления и деление с отбрасыванием дробной части. К сожалению, в языке JavaScript нет оператора целочисленного деления, как в других языках, поэтому для решения задач будем использовать функцию округления'
  },
  {
    code: 'var',
    topic: 'Переменные, оператор присваивания',
    description: 'Ни одна программа не обходится без переменных. Очень важно уметь их применять. В этой теме потренируемся создавать и изменять переменные'
  },
  {
    code: 'boolean',
    topic: 'Истина и ложь',
    description: 'Одним из основных типов данных в JavaScript и в программировании в целом является тип boolean, который имеет всего два значения: true и false. В этой теме рассмотрим его, а также операторы, которые с ним связаны'
  },
  {
    code: 'condition',
    topic: 'Управляющие конструкции: ветвления',
    description: 'Очень часто нужно выполнять разные наборы действий в зависимости от каких-то условий. Чтобы это было возможно, в языке появился соответствующий синтаксис, который мы и рассмотрим в этой теме'
  },
  {
    code: 'loop',
    topic: 'Управляющие конструкции: циклы',
    description: 'Что отличает компьютер от калькулятора? Калькулятор не поможет обработать много однотипных данных. С помощью циклов мы можем заставить компьютер делать однотиные действия многократно. Как раз этим и займёмся в этой теме'
  },
  {
    code: 'array',
    topic: 'Массивы',
    description: 'Многократно повторяемые однотипные действия - это, конечно, хорошо. Но что толку, если мы не умеем запоминать большое количество данных? Массивы - контейнеры для однотипных данных. С помощью них мы сможем ещё сильнее расширить возможности по использованию языка! Также в этой теме рассмотрим цикл for-of'
  }
]
topicsList.forEach((topic, idx) => {
  topic.getTasks = () => {
    const folder = `${String(idx + 1).padStart(2, '0')}-${topic.code}`
    return import('@/data/java/' + folder + '/tasks.js')
  }
})
export const topicsMap = new Map(topicsList.map(item => [item.code, item]))
