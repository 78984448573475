<template>
  <div>
    <h1 class="text-center">{{ course }}</h1>
    <div class="flex-sb mnt-medium row">
      <div v-for="(topic, i) in topics" :key="i" class="card col-6 mt-medium">
        <h2 class="card-title m0">{{ i + 1 }}. {{ topic.topic }}</h2>
        <div class="card-body pad">
          <div>
            {{ topic.description }}
          </div>
        </div>
        <div class="card-footer text-center">
          <div class="flex-center">
            <router-link
              class="btn btn-primary"
              :to="`/${lang}/${topic.code}`"
            >
              Перейти
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { topics } from '../data/langTopics'

export default {
  name: 'LangHome',
  props: ['lang'],
  setup(props) {
    return {
      topics: computed(() => topics[props.lang].list),
      course: computed(() => topics[props.lang].course)
    }
  }
}
</script>

<style scoped>
.btn {
  width: 160px;
}
</style>
