/*
Вёрстка
• 7 из примеров
• пример с дублированием стилей
• тот же пример с классами
• тот же пример с несколькими классами на одном элементе
• подробно про background
• подробно про border, border-radius
• подробно про margin
• подробно про padding
• подробно про рассчёт размеров, box-sizing
• подробно про единицы измерения (px, em, %, vw, vh), calc
• подробно про цвета: словом, #..., #......, rgb, rgba, gradient
• подробно про оформление текста
• подробно про width, height
• подробно про display block, inline, inline-block
• подробно про display table
• подробно про display flex, justify-content, align-items, flex-wrap
• подробно про position static, relative, absolute, fixed
• подробно про css-селекторы + вес селектора, !important
• другие блочные html-теги: h1-h6, p, pre
• другие инлайновые html-теги: span, strong, em
• теги разрывов: br, hr
• теги специального назначения: img, a, iframe, video, audio
• таблицы: table, thead, tbody, tfoot, tr, th, td + связанные стили
• списки: ul, ol, li + связанные стили
• формы: form, input
• формы: textarea + связанные стили, select, option, button
• псевдоклассы состояния - :hover, :active, :focus, :checked
• псевдоклассы :first-child, :last-child, :nth-child
• псевдокласс отрицания :not
• псевдоэлементы ::before и ::after
• другие возможности: box-shadow, text-shadow
• другие возможности: linear-gradient
• другие возможности: float, clear
• другие возможности: overflow
• другие возможности: z-index
• другие возможности: @font-face
• другие возможности: @media
• практика: вёрстка отдельных блоков
*/
/*
HTML/CSS задачи
• Основные понятия
  - Отработка иерархичности HTML 7 заданий
  - CSS в атрибуте style
  - Перенос стилей в классы, назначение классов элементам
  - Основные свойства (поверхностно):
  - width, height, margin, padding, border, outline, background
• Стилизация текста (основное)
  - color, font-family, font-size, font-weight, font-style, text-align
• Единицы измерения (подробно) (px, em, rem, %, vw, vh, vmin, vmax)
• Отступы (подробно)
• Размеры (подробно) + width, height, min-width, max-width, min-height, max-height, box-sizing, calc, min, max
• Рамки (подробно) border, outline
• Фон (подробно) background-*
• Цвета (подробно) - словом, #..., #......, rgb, rgba, gradient
• display: inline / inline-block / block
• Другие блочные и инлайновые HTML-теги
• display: flex
• position
• HTML-теги специального назначения: картинки, таблицы, формы, списки, разрывы, iframe, video, audio
• Другие возможности CSS:
  - псевдоклассы состояния - :hover, :active, :focus, :checked
  - псевдоклассы :first-child, :last-child, :nth-child
  - псевдокласс отрицания :not
  - псевдоэлементы ::before и ::after
  - другие возможности: box-shadow, text-shadow
  - другие возможности: linear-gradient
  - другие возможности: float, clear
  - другие возможности: overflow
  - другие возможности: z-index
  - другие возможности: @font-face
  - другие возможности: @media

*/
/*
HTML/CSS темы
• Иерархия html
• CSS - основные свойства, классы
• Внешние отступы (margin)
• Внутренние отступы (padding)
• Блочные элементы (display: block)
• Инлайновые элементы (display: inline)
• Инлайн-блоки (display: inline-block)
• Флекс (display: flex)
• Стилизация текста, подключение шрифтов
• Таблицы
• Формы
• Другие HTML-теги (span, b/strong, i/em, h1-h6, p, pre, ul, ol, li)
• Теги специального назначения (a, img, br, hr, iframe, video, audio)
• CSS-селекторы, псевдоклассы, вес селектора
• Псевдоэлементы ::before и ::after
• Единицы измерения
• Размеры блока
• Рамки (border)
• Фон (background)
• Цвета, градиент
• Переполнение (overflow)
• Относительное позиционирование (position: relative)
• Абсолютное позиционирование (position: absolute)
• Фиксированное позиционирование (position: fixed)
• Липучее позиционирование (position: sticky)
• Порядок слоёв
• Адаптивность (media)

*/
export const topicsList = [
  {
    code: 'prepare',
    topic: 'Подготовка среды',
    description: 'Для того, чтобы начать верстать, нужно настроить рабочее окружение. В нашем случае нам понадобится установить VS Code, настроить некоторые плагины и добавить папку в workspace'
  },
  {
    code: 'hierarchy',
    topic: 'Иерархия HTML-документа',
    description: 'HTML - язык разметки. Он позволяет описывать иерархичную структуру. В этой теме потренируемся это делать'
  },
  {
    code: 'css-base',
    topic: 'Основные CSS-свойства, классы',
    description: 'Рассмотрим базовые возможности CSS, атрибут style, некоторые свойства, а также что такое классы в CSS и как их применять'
  },
  {
    code: 'margin',
    topic: 'Внешние отступы',
    description: 'С помощью свойства margin можно выставлять внешние отступы, но тут есть несколько неочевидных моментов. Рассмотрим возможности свойства в этой теме'
  },
  {
    code: 'padding',
    topic: 'Внутренние отступы',
    description: 'С помощью свойства padding можно выставлять внутренние отступы. В целом, очень простое свойство, без подводных камней'
  },
  {
    code: 'd-block',
    topic: 'Блочные элементы',
    description: 'Речь про display: block. Свойство display играет максимально важную роль в вёрстке. Оно определяет то, как ведут себя элементы. У него есть порядочное количество значений. Рассмотрим значение block'
  },
  {
    code: 'd-inline',
    topic: 'Инлайновые элементы',
    description: 'Ещё одно допустимое значение для display - inline. Элементы с этим типом отображения ведут себя как текст. Рассмотрим поведение элементов с этип типом'
  },
  {
    code: 'd-inline-block',
    topic: 'Инлайн-блоки',
    description: 'Очередное значение свойства display - inline-block. Элементы этого типа тоже ведут себя как текст, но имеют нескоторые свойства блоков. Рассмотрим какие именно'
  },
  {
    code: 'd-flex',
    topic: 'Флекс',
    description: 'Относительно недавно появившееся значение display - flex. Это поистине революционный тип, который координально упростил жизнь многим верстальщикам. Он позволяет делать очень много всего. В современной вёрстке без него никуда'
  },
  {
    code: 'text',
    topic: 'Стилизация текста',
    description: 'В этой теме рассмотрим всё, что касается форматирования текста. Шрифты, их подключение, размер, выделение жирным, курсивом, абзацы, ширина строки, отступы между буквами и прочее'
  },
  {
    code: 'table',
    topic: 'Таблицы',
    description: 'В этой теме рассмотрим всё, что касается таблиц. Какие специальные теги есть для таблиц, как можно сделать таблицу без тегов, CSS-свойства, относящиеся к таблицам, HTML-атрибуты и прочее'
  },
  {
    code: 'form',
    topic: 'Формы',
    description: 'Всё, что касается пользовательского ввода. Текстовые поля, выпадающие списки, чекбоксы, выбор файлов, кнопки, метки, а также CSS-свойства, относящиеся к этому'
  },
  {
    code: 'other-tags',
    topic: 'Другие HTML-теги',
    description: 'Пока мы рассматривали основные теги. Теперь рассмотрим, что ещё есть в HTML, как это применить или не применять. Семантические и стилизующие теги, а также как можно обойтись без них'
  },
  {
    code: 'special-tags',
    topic: 'Теги специального назначения',
    description: 'Это такие теги, которые обрабатываются браузером особым образом. В том числе ссылки, картинки, переносы строк, вставка других страниц, аудио и видео'
  },
  {
    code: 'selectors',
    topic: 'CSS-селекторы',
    description: 'До этого мы использовали очень скромные возможности по выборке элементов для стилизации. Пришло время рассмотреть более продвинутые возможности выборки'
  },
  {
    code: 'pseudo-elem',
    topic: 'Псевдоэлементы',
    description: 'С помощью псевдоэлементов можно добавлять элементы в документ из CSS, не затрагивая HTML. Есть ограничения, но это позволяет делать проще некоторые вещи, ради которых раньше приходилось бы добавлять дополнительные теги в HTML'
  },
  {
    code: 'measures',
    topic: 'Единицы измерения',
    description: 'Рассмотрим, какие есть единицы измерения в CSS, как они работают, а также как можно их комбинировать с помощью вычислительных функций calc, max и min'
  },
  {
    code: 'size',
    topic: 'Размеры блока',
    description: 'Рассмотрим, какие возможности у нас есть для задания размеров блоков, а также алгоритм вычисления размеров, и как его можно менять'
  },
  {
    code: 'border',
    topic: 'Рамки',
    description: 'Всё что касается рамок. За рамки отвечает свойство border. Рассмотрим варианты его применения'
  },
  {
    code: 'background',
    topic: 'Фон',
    description: 'Всё что касается фона. Установка в качестве фона цвета, картинки, смещения, растягивание и прочие возможности по изменению фона'
  },
  {
    code: 'color-gradient',
    topic: 'Цвета, градиент',
    description: 'Всё что касается определения цвета. Какие есть способы задать цвет, что значат цифры, как сделать градиент'
  },
  {
    code: 'shadow',
    topic: 'Тень',
    description: 'Всё что касается теней. Тень может быть у текста и у блока. Посмотрим, как их настраивать и что можно сделать с их помощью'
  },
  {
    code: 'overflow',
    topic: 'Переполнение',
    description: 'Как ведут себя блоки, если не помещаются в родительский элемент? На этот вопрос отвечае свойство overflow. Посмотрим, какие есть варианты'
  },
  {
    code: 'pos-relative',
    topic: 'Относительное позиционирование',
    description: 'Переходим к другой важной теме - позиционирование. Есть задачи, которые легче и проще решаются позиционированием, чем без без него. В этой теме рассмотрим свойство position со значением relative'
  },
  {
    code: 'pos-absolute',
    topic: 'Абсолютное позиционирование',
    description: 'Другой вид позиционирования - абсолютный (position: absolute). У него есть некоторые особенности, которые не очень очевидны для понимания, но при этом их нужно понимать обязательно, чтобы не наступать каждый раз на грабли'
  },
  {
    code: 'pos-fixed',
    topic: 'Фиксированное позиционирование',
    description: 'Ещё одно значение position - fixed. Имеет весьма специфичную область применения. И есть класс задач, которые без него не решаются. Посмотрим, что оно из себя представляет'
  },
  {
    code: 'pos-sticky',
    topic: 'Липучее позиционирование',
    description: 'Очень специфическое значение для position - sticky. Похож на fixed, но не в рамках экрана, а в рамках родительского элемента. Никогда не применял, но видел как применяют'
  },
  {
    code: 'z-index',
    topic: 'Порядок слоёв',
    description: 'Какой элемент будет виден на переднем плане, а какой на заднем, если спозиционировать элементы так, чтобы они пересекались? И как можно этот порядок изменить? Рассмотрим в этой теме свойство z-index'
  },
  {
    code: 'animation',
    topic: 'Анимация',
    description: 'В CSS3 появилась возможность делать некоторые анимации без использования JavaScript. Это проще и эффективнее. Рассмотрим, как можно этим пользоваться'
  },
  {
    code: 'media',
    topic: 'Адаптивность',
    description: 'В современном web-е очень большое разнообразие экранов, на которых открываются наши страницы. Очень часто нужно, чтобы сайт смотрелся хорошо и на больших, и на маленьких экранах. Как этого можно добиться? Рассмотрим в этой теме'
  }
]
topicsList.forEach((topic, idx) => {
  topic.getTasks = () => {
    const folder = `${String(idx + 1).padStart(2, '0')}-${topic.code}`
    return import('@/data/html/' + folder + '/tasks.js')
  }
})
export const topicsMap = new Map(topicsList.map(item => [item.code, item]))
