<template>
  <h1 class="text-center">Добро пожаловать!</h1>
  <p>Вы попали на сайт по обучению программированию.</p>
  <p>
    На этом сайте собраны задачи и теоретические материалы, которые помогут
    разобраться в основах программирования даже тем людям, которые никогда не
    занимались программированием.
  </p>
  <p>
    Задачи составлены таким образом, чтобы закрепить на практике все аспекты
    теоретического материала.
  </p>
  <p>Чтобы начать работу, выберите интересующий Вас язык в верхнем меню.</p>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
