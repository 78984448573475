import { topicsList as htmlTopicsList, topicsMap as htmlTopicsMap } from '../data/html/topics'
import { topicsList as jsTopicsList, topicsMap as jsTopicsMap } from '../data/js/topics'
import { topicsList as pythonTopicsList, topicsMap as pythonTopicsMap } from '../data/python/topics'
import { topicsList as javaTopicsList, topicsMap as javaTopicsMap } from '../data/java/topics'

export const topics = {
  html: {
    course: 'HTML/CSS',
    list: htmlTopicsList,
    map: htmlTopicsMap
  },
  js: {
    course: 'JavaScript',
    list: jsTopicsList,
    map: jsTopicsMap
  },
  python: {
    course: 'Python',
    list: pythonTopicsList,
    map: pythonTopicsMap
  },
  java: {
    course: 'Java',
    list: javaTopicsList,
    map: javaTopicsMap
  }
}
