export const topicsList = [
  {
    code: 'prepare',
    topic: 'Подготовка среды',
    description: 'Для того, чтобы начать программировать, нужно настроить рабочее окружение. В нашем случае нам понадобится установить Node.js и VS Code, настроить некоторые плагины, создать проект и настроить линтер'
  },
  {
    code: 'arithm',
    topic: 'Простая арифметика',
    description: 'В этой теме мы научимся делать простые арифметические вычисления с помощью языка программирования. Также важно будет получить навыки правильного оформления кода и использования клавиатурных сокращений'
  },
  {
    code: 'int',
    topic: 'Целочисленная арифметика',
    description: 'Для решения множества вычислительных задач очень часто применяется целочисленная арифметика. Основа целочисленной арифметики - это операция взятия остатка от деления и деление с отбрасыванием дробной части. К сожалению, в языке JavaScript нет оператора целочисленного деления, как в других языках, поэтому для решения задач будем использовать функцию округления'
  },
  {
    code: 'var',
    topic: 'Переменные, оператор присваивания',
    description: 'Ни одна программа не обходится без переменных. Очень важно уметь их применять. В этой теме потренируемся создавать и изменять переменные'
  },
  {
    code: 'boolean',
    topic: 'Истина и ложь',
    description: 'Одним из основных типов данных в JavaScript и в программировании в целом является тип boolean, который имеет всего два значения: true и false. В этой теме рассмотрим его, а также операторы, которые с ним связаны'
  },
  {
    code: 'condition',
    topic: 'Ветвления',
    description: 'Очень часто нужно выполнять разные наборы действий в зависимости от каких-то условий. Чтобы это было возможно, в языке появился соответствующий синтаксис, который мы и рассмотрим в этой теме'
  },
  {
    code: 'loop',
    topic: 'Циклы',
    description: 'Что отличает компьютер от калькулятора? Калькулятор не поможет обработать много однотипных данных. С помощью циклов мы можем заставить компьютер делать однотиные действия многократно. Как раз этим и займёмся в этой теме'
  },
  {
    code: 'array',
    topic: 'Массивы',
    description: 'Многократно повторяемые однотипные действия - это, конечно, хорошо. Но что толку, если мы не умеем запоминать большое количество данных? Массивы - контейнеры для однотипных данных. С помощью них мы сможем ещё сильнее расширить возможности по использованию языка! Также в этой теме рассмотрим цикл for-of'
  },
  {
    code: 'func',
    topic: 'Функции',
    description: 'До этого мы использовали функции, которые не возвращают никаких значений. Эти функции выполняли какие-то вычисления и выводили результат на консоль. При этом нельзя было использовать результат вычислений за пределами функции. Теперь рассмотрим другие возможности функций'
  },
  {
    code: 'string',
    topic: 'Строки',
    description: 'Один из самых основных типов данных - string. Используется для записи текстовых данных. Очень много работы программиста приходится именно на работу со строками. Рассмотрим, что в JavaScript есть для этого'
  },
  {
    code: 'object',
    topic: 'Объекты',
    description: 'Одна из главных тем. Без объектов код на JavaScript не может существовать. Большинство данных в JavaScript хранится в виде объектов. Пришло время научиться их готовить'
  },
  {
    code: 'algo',
    topic: 'Алгоритмы',
    description: 'Мы уже научились выполнять много однотипных действий и запоминать результаты. В наших руках огромная сила! Но эта сила не безгранична! Даже самый мощный компьютер можно заставить долго думать, если не беспокоиться о скорости своих программ. В этой теме посмотрим некоторые алгоритмы, а также время выполнения разных вариантов решения задач'
  },
  {
    code: 'link',
    topic: 'Структуры данных',
    description: 'Обычно алгоритмы идут рука об руку со структурами данных. Какие-то структуры больше подходят для одних задач, какие-то для других. В этой теме рассмотрим ссылочные структуры данных: связный список (плюс стек и очередь на базе связного списка) и дерево'
  },
  {
    code: 'oop',
    topic: 'ООП',
    description: 'Объектно-ориентированное программирование. В мире фронтенд-разработки сейчас пытаются уйти от классов, поэтому я считаю эту тему не особо важной. Тем не менее, инструмент весьма мощный, и его, пожалуй, стоит изучить. В этой теме попробуем сделать некоторые вещи с помощью классов'
  },
  {
    code: 'setmap',
    topic: 'Set и Map',
    description: 'Множество (set) и мап (map) - два вида абстрактных структур данных. Set позволяет запоминать только уникальные значения, а также быстро проверять, есть ли конкретное значение в коллекции. В map, в отличие от set, каждому элементу ставится в соответствие некоторое значение. Принцип действия аналогичен обычным объектам, кроме того, что в map ключи могут быть любым типом данных, а не только string'
  },
  {
    code: 'regex',
    topic: 'Регулярные выражения',
    description: 'Регулярные выражения - это мощный инструмент обработки текста. С помощью них можно легко проверить, соответствует ли строка какому-то шаблону, или вытащить какую-то часть строки, соответсвующую определённому шаблону, или произвести замену какой-то определённой части. Например, с помощью них часто делают валидацию данных, но это далеко не единственное их применение'
  },
  {
    code: 'dom',
    topic: 'DOM-дерево',
    description: 'Объектная модель документа. Смотрим, какие возможности по изменению страниц нам предоставляет браузер. Желательно к моменту прохождения знать основы html и css'
  },
  {
    code: 'time',
    topic: 'Отложенные вызовы',
    description: 'В этой теме рассмотрим встроенные инструменты работы с отложенными вызовами функций. С помощью них можно делать какие-то изменения на странице (и не только), подождав какое-то определённое время. Также с помощью этих инструментов можно делать анимации'
  },
  {
    code: 'promise',
    topic: 'Промисы, асинхронные функции',
    description: 'Очень часто в JavaScript нужно делать что-то, когда произойдёт какое-то событие. Например, нужно вызвать функцию, когда пройдёт определённое время, или когда был получен ответ на какой-то сетевой запрос. Есть несколько способов сделать это. Можно использовать колбэки, промисы и асинхронные функции. Рассмотрим их более подробно'
  },
  {
    code: 'ajax',
    topic: 'Сетевые запросы',
    description: 'В современном фронтенде нам часто нужно получать информацию с сервера без перезагрузки страницы, то есть в фоновом режиме. Для этого мы должны писать запросы на JavaScript. Какие есть способы и как это делается рассмотрим в этой главе'
  },
  {
    code: 'practice',
    topic: 'Прикладное программирование',
    description: 'Мы уже знаем достаточно, чтобы решить большинство прикладных фронтенд-задач. Но знать - это одно, а уметь - это другое. В этой теме попытаемся набить руку на решении прикладных задач. Разумеется, это не последний шаг в изучении фронтенда. Есть множество сопутствующих технологий, которые помогут решать бизнес-задачи более эффективно, но изучение этих инструментов уже выходит за рамки этого курса'
  }
]
topicsList.forEach((topic, idx) => {
  topic.getTasks = () => {
    const folder = `${String(idx + 1).padStart(2, '0')}-${topic.code}`
    return import('@/data/js/' + folder + '/tasks.js')
  }
})
export const topicsMap = new Map(topicsList.map(item => [item.code, item]))
