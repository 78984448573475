<template>
    <div class="header">
        <div class="container pad">
            <router-link to="/">Главная</router-link>
            <router-link to="/html">HTML/CSS</router-link>
            <router-link to="/js">JavaScript</router-link>
            <router-link to="/python">Python</router-link>
            <router-link to="/java">Java</router-link>
        </div>
    </div>

    <div class="container pad page">
        <router-view />
    </div>
</template>

<script>
import './assets/common.css'
export default {
  name: 'App'
}
</script>

<style scoped>
.header {
  background: #343a40;
}
.header a:hover {
  color: #41ff1f;
}
.header a {
  color: white;
  padding: 10px;
  text-decoration: none;
}
.page {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  margin-bottom: 15px;
}
</style>
